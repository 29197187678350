@import 'Styles/includes';

/**
*
* CardLogoList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardLogoList {
    &__Tag {
        font-size: 1.6rem;
        font-weight: 700;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        text-align: center;
        border-bottom: 1px solid $gray-20;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__List {
        margin-left: -24px;
        display: flex;
        flex-wrap: wrap;

        @include media(md) {
            justify-content: center;
            margin-left: -56px;
        }
    }

    &__Item {
        margin-bottom: 16px;
        width: calc(50% - 24px);
        margin: 0 0 16px 24px;

        @include media(md) {
            margin: 0 0 16px 56px;
            width: calc(100% / 5 - 56px);
        }

        &--Few {
            @include media(md) {
                margin: 0 0 16px 56px;
                width: calc(100% / 3 - 56px);
                flex-grow: 0;
            }
        }

        &--Single {
            width: calc(100% - 24px);
    
            @include media(md) {
                margin: 0 0 16px 56px;
                width: calc(100% / 3 - 56px);
                flex-grow: 0;
            }
        }
    }
}