@import 'Styles/includes';

/**
*
* CardLogo
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardLogo {
    width: 100%;

    &__Link {
        text-decoration: none;
        color: $text;
    }

    &__Logo {
        width: 100%;
        height: 160px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        margin-bottom: 16px;
    }

    &__Title {
        text-align: center;
        text-decoration: none;
    }
}